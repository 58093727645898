// document.querySelectorAll(".nav-link").forEach((link) => {
//     if (link.href === window.location.href) {
//         link.classList.add("active");
//         link.setAttribute("aria-current", "page");
//     }
// });


const menuMobile = document.querySelector('#menu-mobile');
const overlay = document.querySelector('#overlay');
const showMenu = document.querySelector('#show-menu');
const hideMenu = document.querySelector('#hide-menu');

const classList = {
    visible = 'visible',
    hidden = 'hidden',
    block = 'block',
    none = 'none'
}

const toggleElement = (element, addClass, removeClass) => {
    element.classList.add(addClass);
    element.classList.remove(removeClass);
}

showMenu.addEventListener('click', () => {
    toggleElement(overlay, classList.visible, classList.hidden);
    toggleElement(menuMobile, classList.visible, classList.hidden);
    toggleElement(hideMenu, classList.block, classList.none);
    toggleElement(showMenu, classList.none, classList.block);
});

hideMenu.addEventListener('click', () => {
    toggleElement(overlay, classList.hidden, classList.visible);
    toggleElement(menuMobile, classList.hidden, classList.visible);
    toggleElement(hideMenu, classList.none, classList.block);
    toggleElement(showMenu, classList.block, classList.none);
});





const THUMBNAILS = document.querySelectorAll(".thumbnail img");
const POPUP = document.querySelector(".popup");
const POPUP_CLOSE = document.querySelector(".popup__close");
const POPUP_IMG = document.querySelector(".popup__img");
const ARROW_LEFT = document.querySelector(".popup__arrow--left");
const ARROW_RIGHT = document.querySelector(".popup__arrow--right");

let currentImgIndex;

const showNextImg = () => {
    if (currentImgIndex === THUMBNAILS.length - 1) {
        currentImgIndex = 0;
    } else {
        currentImgIndex++;
    }
    POPUP_IMG.src = THUMBNAILS[currentImgIndex].src;
};

const showPreviousImg = () => {
    if (currentImgIndex === 0) {
        currentImgIndex = THUMBNAILS.length - 1;
    } else {
        currentImgIndex--;
    }
    POPUP_IMG.src = THUMBNAILS[currentImgIndex].src;
};

const closePopup = () => {
    POPUP.classList.add("fade-out");
    setTimeout(() => {
        POPUP.classList.add("hidden");
        POPUP.classList.remove("fade-out");
        THUMBNAILS.forEach((element) => {
            element.setAttribute("tabindex", 1);
        });
    }, 300);
};

THUMBNAILS.forEach((thumbnail, index) => {
    const showPopup = (e) => {
        POPUP.classList.remove("hidden");
        POPUP_IMG.src = e.target.src;
        currentImgIndex = index;
        THUMBNAILS.forEach((element) => {
            element.setAttribute("tabindex", -1);
        });
    };

    thumbnail.addEventListener("click", showPopup);

    thumbnail.addEventListener("keydown", (e) => {
        if (e.code === "Enter" || e.keyCode === 13) {
            showPopup(e);
        }
    });
});

POPUP_CLOSE.addEventListener("click", closePopup);

ARROW_RIGHT.addEventListener("click", showNextImg);

ARROW_LEFT.addEventListener("click", showPreviousImg);

document.addEventListener("keydown", (e) => {
    if (!POPUP.classList.contains("hidden")) {
        if (e.code === "ArrowRight" || e.keyCode === 39) {
            showNextImg();
        }

        if (e.code === "ArrowLeft" || e.keyCode === 37) {
            showPreviousImg();
        }

        if (e.code === "Escape" || e.keyCode === 27) {
            closePopup();
        }
    }
});

POPUP.addEventListener("click", (e) => {
    if (e.target === POPUP) {
        closePopup();
    }
});
